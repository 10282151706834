import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PositionButtonGroup from "./PositionButtonGroup";
import InnerPlayerData from "./InnerPlayerData";
import InfoBox from "../../../components/common/InfoBox";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import {
  getAssistersApi,
  getBonusApi,
  getExpectedAssistsApi,
  getExpectedGoalsApi,
  getExpectedGoalsInvolvementApi,
  getRecentPointersApi,
  getScorersApi,
  getSelectionApi,
  getTopPointersApi,
  getWeeklyPointersApi,
} from "../api/getTopData";

interface TopPointsBoxProps {
  title: string;
  playerData?: any[];
  position?: string;
  setPosition?: (position: string) => void;
  allTeamInfo: any[];
  currentRound: number;
  option: string;
}

const TopPointsBox: React.FC<TopPointsBoxProps> = ({ title, allTeamInfo, currentRound, option }) => {
  const [position, setPosition] = useState<string>("");
  const [playerData, setPlayerData] = useState<any[]>([]);

  const getPlayerData = async () => {
    let result: any;
    if (option === "event_point") {
      result = await getWeeklyPointersApi(position);
    } else if (option === "total_point") {
      result = await getTopPointersApi(position);
    } else if (option === "recent_point") {
      result = await getRecentPointersApi(position);
    } else if (option === "goals") {
      result = await getScorersApi(position);
    } else if (option === "assists") {
      result = await getAssistersApi(position);
    } else if (option === "bonus") {
      result = await getBonusApi(position);
    } else if (option === "selection") {
      result = await getSelectionApi(position);
    } else if (option === "expected_goals") {
      result = await getExpectedGoalsApi(position);
    } else if (option === "expected_assists") {
      result = await getExpectedAssistsApi(position);
    } else if (option === "expected_goal_involvements") {
      result = await getExpectedGoalsInvolvementApi(position);
    }
    setPlayerData(result);
  };

  useEffect(() => {
    getPlayerData();
  }, [position]);

  if (!playerData) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <BoxTitle>{title}</BoxTitle>
      <PositionButtonGroupContainer>
        <PositionButtonGroup position={position} setPosition={setPosition} />
      </PositionButtonGroupContainer>
      <BoxContent>
        {playerData.length > 0
          ? playerData.map((data: any) => {
              return (
                <InnerPlayerData
                  key={data.id}
                  data={data}
                  teamInfo={allTeamInfo}
                  currentRound={currentRound}
                  option={option}
                />
              );
            })
          : null}
      </BoxContent>
    </Box>
  );
};

export default TopPointsBox;

const Box = styled.div`
  width: calc(100% / 2 - 2rem); // Responsive width for 2 boxes per row
  box-sizing: border-box; // Keeps padding within the box width
  transition: all 0.3s ease; // Smooth transition for hover effect
  border-radius: 1rem; // Rounded corners
  /* background-color: ${colors.infoBoxBackground}; // Light grey background: ; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Light shadow effect

  &:hover {
    /* transform: translateY(-5px); // Slight lift effect on hover */
  }

  @media (max-width: 1268px) {
    width: 100%; // Full width on smaller screens
    margin: 1rem auto;
  }
`;

const BoxTitle = styled.div`
  font-size: ${fontSize.media_large.medium}; // Larger font size for title
  font-weight: 600; // Bold font weight for emphasis
  color: ${colors.baseText}; // Dark grey color for title
  padding: 1rem; // Padding for spacing
  border-bottom: 1px solid ${colors.borderColor}; // Light grey border
  /* background-color: ${colors.infoBoxBackground}; // Light background for title */
  border-radius: 1rem 1rem 0 0; // Rounded top corners
`;

const PositionButtonGroupContainer = styled.div`
  padding: 0.5rem 0.5rem;
  /* background-color: #f9f9f9; // Light background for button group */
  border-radius: 0 0 1rem 1rem; // Rounded bottom corners

  @media (max-width: 768px) {
    padding: 0rem;
    margin: 1rem auto;
  }
`;

const BoxContent = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { useState } from "react";
import ManagerDetail from "./ManagerDetail";

const ManagerInfo = ({ manager }: any) => {
  const [activeTab, setActiveTab] = useState("overall"); // Default tab

  return (
    <Container>
      <TabContainer>
        <TabButton selected={activeTab === "overall"} onClick={() => setActiveTab("overall")}>
          Overall Data
        </TabButton>
        <TabButton selected={activeTab === "eventHistory"} onClick={() => setActiveTab("eventHistory")}>
          Event History
        </TabButton>
        {/* <TabButton selected={activeTab === "suggestions"} onClick={() => setActiveTab("suggestions")}>
          Suggestions
        </TabButton> */}
      </TabContainer>
      <ManagerDetail activeTab={activeTab} />
    </Container>
  );
};

export default ManagerInfo;

const Container = styled.div``;

const TabContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 1rem;
  gap: 1rem;
`;

const TabButton = styled.button<{ selected: boolean }>`
  padding: 0.5rem 1rem;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? colors.buttonHover : "transparent")};
  color: ${(props) => (props.selected ? colors.textBlack : colors.baseText)};
  border: none;
  border-radius: 5px;
  font-size: 1rem;

  &:hover {
    background-color: ${colors.buttonHover};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

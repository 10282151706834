import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FaSearch } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import colors from "../assets/colors/colors";
import { useRecoilState } from "recoil";
import { optionState } from "../state/option";
import { searchState } from "../state/search";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../hooks/useLoading";
import { fontSize } from "../assets/style/font";
import { NAVBAR_EXPAND_WIDTH } from "../constants/const";

interface SearchBarProps {
  openModal: (player: string) => void;
  searchManagerId: (managerId: number) => void;
  searchManagerName: (managerName: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ openModal, searchManagerId, searchManagerName }) => {
  const [option, setOption] = useRecoilState(optionState);
  const [search, setSearch] = useRecoilState(searchState);
  const [searchType, setSearchType] = useState("player");
  const [searchTerm, setSearchTerm] = useState(
    searchType === "player" ? search.playerName : searchType === "managerId" ? search.managerId : search.managerName
  );

  const navigate = useNavigate();
  const { isLoading, withLoading } = useLoading(); // useLoading 훅 사용

  const handleSearch = async () => {
    await withLoading(async () => {
      if (searchType === "player") {
        await openModal(searchTerm.toString());
      } else if (searchType === "managerId") {
        const managerId = parseInt(searchTerm);
        if (managerId) {
          setOption({ ...option, managerMenuOption: "search" });
          setSearch({ ...search, managerId: managerId });
          await searchManagerId(managerId);
        }
      } else {
        setOption({ ...option, managerMenuOption: "search" });
        setSearch({ ...search, managerName: searchTerm });
        await searchManagerName(searchTerm);
      }
      navigate(`/manager`);
    });
  };

  const searchPlaceHolder = () => {
    if (searchType === "player") return "Player Name(ex)Salah";
    if (searchType === "managerId") return "Manager ID";
    return "Enter Manager Name";
  };

  return (
    <StyledSearchContainer>
      <StyledSearchForm>
        <SearchType>
          <TypeButton
            isActive={searchType === "player"}
            onClick={() => {
              setSearchTerm("");
              setSearchType("player");
            }}
          >
            Player
          </TypeButton>
          <TypeButton
            isActive={searchType === "managerId"}
            onClick={() => {
              setSearchTerm("");
              setSearchType("managerId");
            }}
          >
            Manager Id
          </TypeButton>
        </SearchType>
        <StyledSearchInput
          type="text"
          placeholder={searchPlaceHolder()}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(); // Enter key triggers search
            }
          }}
        />
        <StyledSearchButton onClick={handleSearch} disabled={isLoading}>
          {isLoading ? <LoadingIcon /> : <FaSearch />}
        </StyledSearchButton>
      </StyledSearchForm>
    </StyledSearchContainer>
  );
};

export default SearchBar;

// Styled Components
const StyledSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const StyledSearchForm = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.inputBackground};
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  max-width: 600px;
  width: 100%;
  border: 1px solid ${colors.baseBorder};

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    padding: 0.2rem 0.2rem;
  }
`;

const SearchType = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    margin-right: 0.5rem;
  }
`;

interface TypeButtonProps {
  isActive: boolean;
}

const TypeButton = styled.button<TypeButtonProps>`
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background: ${(props) => (props.isActive ? colors.buttonActive : colors.inputBackground)};
  color: ${(props) => (props.isActive ? colors.primary : colors.textBlack)};
  font-size: 0.875rem;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${colors.baseBoxBackground};
  }

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    font-size: ${fontSize.media_small.medium};
    padding: 0.1rem 0.2rem;
  }
`;

const StyledSearchInput = styled.input`
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: ${colors.textBlack};
  background: ${colors.inputBackground};
  border: none;
  border-radius: 0.5rem;
  outline: none;

  &:focus {
    background: ${colors.baseBoxBackground};
  }

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    font-size: ${fontSize.media_small.medium};
    padding: 0.2rem 0.2rem;
    width: 120px;
  }
`;

const StyledSearchButton = styled.button`
  padding: 0.5rem 0.75rem;
  margin-left: 0.5rem;
  background: ${colors.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  &:hover {
    background: ${colors.buttonHover};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${colors.disabledButton};
  }

  svg {
    font-size: 1.25rem;
  }

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    padding: 0.2rem 0.2rem;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingIcon = styled(AiOutlineLoading)`
  font-size: 1.25rem;
  animation: ${spin} 1s linear infinite;
  color: white;
`;

import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import colors from "../../../assets/colors/colors";

interface GraphProps {
  data: { ranks: { event: string; overall_rank: number; points: number; rank: number }[] };
}

const Graph: React.FC<GraphProps> = ({ data }) => {
  const options: ApexOptions = {
    theme: {
      mode: "light", // You can set this to 'dark' for a dark theme
      palette: "palette1", // There are several palettes available in ApexCharts
      monochrome: {
        enabled: false,
        color: "black",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    chart: {
      height: "auto",
      background: "none",
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2, // Adjust line thickness here
    },
    title: {
      text: "Player Ranks, Event Ranks, and Points",
      align: "left",
      style: {
        color: `${colors.baseText}`,
        fontFamily: "inherit",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.3,
      },
    },
    xaxis: {
      categories: data.ranks.map((obj) => obj.event), // 이벤트 이름
      labels: {
        minHeight: 50,
        style: {
          colors: `${colors.baseText}`,
          fontFamily: "inherit",
        },
      },
      tickAmount: 20,
    },
    yaxis: [
      {
        title: {
          text: "Rank",
          style: {
            color: `${colors.baseText}`,
            fontFamily: "inherit",
          },
        },
        decimalsInFloat: 0,
        labels: {
          formatter: function (value) {
            return value + "k"; // 천 단위로 표현
          },
          style: {
            colors: `${colors.baseText}`,
            fontFamily: "inherit",
          },
        },
        reversed: true, // Overall Rank가 높은 값이 아래에
        min: 0,
        max: 10000,
      },
      {
        opposite: true,
        title: {
          text: "Event Points",
          style: {
            color: `${colors.baseText}`,
            fontFamily: "inherit",
          },
        },
        labels: {
          style: {
            colors: `${colors.baseText}`,
            fontFamily: "inherit",
          },
        },
        decimalsInFloat: 0,
      },
      {
        decimalsInFloat: 0,
        labels: {
          formatter: function (value) {
            return value + "k"; // 천 단위로 표현
          },
          style: {
            colors: "black",
            fontFamily: "inherit",
          },
          show: false,
        },
        reversed: true, // Event Rank도 높은 값이 아래에
        min: 0,
        max: 10000,
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "40%", // Adjust bar width here
      },
    },
    states: {
      hover: {
        filter: {
          type: "none", // Disable default hover effects by setting to 'none'
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: "none", // Similarly, disable effects for active (clicked) state
        },
      },
    },
    responsive: [
      {
        breakpoint: 800,
        options: {
          chart: {
            height: 300,
          },
          xaxis: {
            tickAmount: 5,
          },
        },
      },
    ],
    tooltip: {
      theme: "dark",
      fillSeriesColor: true,
      x: {
        show: true,
      },
    },
    series: [
      {
        name: "Overall Ranks",
        type: "line",
        data: data.ranks.map((obj) => obj.overall_rank / 1000), // Overall rank, divided by 1000 for 'k' display
      },
      {
        name: "Event Points",
        type: "column",
        data: data.ranks.map((obj) => obj.points), // Event Points
      },
      {
        name: "Event Rank",
        type: "line",
        data: data.ranks.map((obj) => obj.rank / 1000), // Event Rank, divided by 1000 for 'k' display
      },
    ],
    legend: {
      labels: {
        colors: `${colors.baseText}`,
        useSeriesColors: false,
      },
    },
  };

  return (
    <div id="chart" style={{ width: "100%", color: "black" }}>
      <ReactApexChart options={options} series={options.series} type="line" height={350} />
    </div>
  );
};

export default Graph;

import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import colors from "../../../assets/colors/colors";
import { getPlayerImgSrcById } from "../../../utils/getPlayerImgSrcById";

interface InfoBoxProps {
  playerFacts: any; // Replace 'any' with the actual type of your playerFacts data
}

const PlayerInfoBox: React.FC<InfoBoxProps> = ({ playerFacts }) => {
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [playerImgSrc, setPlayerImgSrc] = useState("");

  useEffect(() => {
    if (playerFacts.length > 0) {
      setPlayerImgSrc(getPlayerImgSrcById(playerFacts[currentFactIndex].code));
    }
  }, [currentFactIndex, playerFacts]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentFactIndex((prevIndex) => (prevIndex + 1 >= playerFacts.length ? 0 : prevIndex + 1));
    }, 3000); // Change fact every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [playerFacts.length]);

  return (
    <Container>
      <PlayerImg src={playerImgSrc}></PlayerImg>

      {playerFacts.length > 0 && (
        <InfoText>
          <InfoName>{playerFacts[currentFactIndex].web_name}</InfoName>
          <InfoNews>{playerFacts[currentFactIndex].news}</InfoNews>
        </InfoText>
      )}
    </Container>
  );
};

export default PlayerInfoBox;

const Container = styled.div`
  background-color: ${colors.infoBox};
  color: black;
  padding: 0.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin: 1rem auto; // Center the box
  border-radius: 8px; // Softer border radius
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Adds depth with a shadow
  position: relative; // Needed for the tooltip arrow
  max-width: 600px; // Set a max-width for better readability
  text-align: left; // Align text to the left for better readability
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PlayerImg = styled.img`
  width: 5rem;

  @media (max-width: 500px) {
    width: 3rem;
    margin-right: 1rem;
  }
`;

const InfoText = styled.h2`
  color: black;
  animation: ${fadeIn} 1s ease-in-out;
  line-height: 1.4; // Better line spacing
`;

const InfoName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const InfoNews = styled.div`
  font-size: 1rem;
  color: #333;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

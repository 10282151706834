import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa"; // Sort icons
import MiniLeagueRankingTable from "../../../components/MiniLeagueRankingTable";
import { dataState } from "../../../state/data";
import { searchState } from "../../../state/search";
import { optionState } from "../../../state/option";
import { getManagerData } from "../../../api/manager/getManagerData";
import { getPoints } from "../../../api/miniLeague/getPoints";
import CaptainBarChart from "../../../components/CaptainBarChart";
import { Title } from "../../../assets/style/Title";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import InfoBox from "../../../components/common/InfoBox";

interface CaptainChartData {
  leagueData: any[];
}

const CaptainChartContainer: React.FC<CaptainChartData> = ({ leagueData }) => {
  const [pointsData, setPointsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

  // 쿨타임 타이머 관리
  useEffect(() => {
    if (isCooldown && cooldownTimeLeft > 0) {
      const interval = setInterval(() => {
        setCooldownTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => clearInterval(interval); // 타이머 클리어
    } else if (cooldownTimeLeft <= 0) {
      setIsCooldown(false); // 쿨타임 끝나면 다시 버튼 활성화
    }
  }, [isCooldown, cooldownTimeLeft]);

  //   useEffect(() => {
  //     const fetchNewEntries = async () => {
  //       try {
  //         const results: any = await getPoints();
  //         const points = results.data;
  //         setPointsData(points);
  //       } catch (error) {
  //         console.error("Error fetching new entries:", error);
  //       }
  //     };
  //     fetchNewEntries();
  //   }, [loading]);

  return (
    <Container>
      <RankingContainer>
        <RankingTitle style={{ marginTop: "2rem" }}>Captain Picks</RankingTitle>
        <CaptainBarChart
          data={leagueData.reduce((acc: any, data: any) => {
            const captain = data.current_captain;
            if (captain) {
              acc[captain] = (acc[captain] || 0) + 1;
            }
            return acc;
          }, {})}
        />
      </RankingContainer>
    </Container>
  );
};

export default CaptainChartContainer;

const Container = styled.div`
  /* background-color: ${colors.rankingBackground}; */
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0rem;
  }
`;

const RankingContainer = styled.div`
  /* background-color: ${colors.rankingBackground}; */
  padding: 1rem;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0rem;
  }
`;

const RankingTitle = styled(Title)`
  text-align: left;
  margin-left: 2rem;
  color: ${colors.baseText};
  margin-bottom: 1.5rem;
  font-size: ${fontSize.media_large.large};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.large};
    margin: 0.5rem 0;
    text-align: center;
  }
`;

import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../../../assets/colors/colors";
import { useRecoilState } from "recoil";
import { optionState } from "../../../state/option";
import { fontSize } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import { Components } from "../../../assets/style/Components";
import InfoBox from "../../../components/common/InfoBox";

const Banner = () => {
  const [option, setOption] = useRecoilState(optionState);
  return (
    <Container>
      <BestTitle>Having trouble with your transfers?</BestTitle>
      <RouterContainer>
        <Router
          to="/player"
          onClick={() => {
            setOption({
              ...option,
              playerMenuOption: 0,
            });
          }}
        >
          BEST PICKS
        </Router>
        <Router
          to="/player"
          onClick={() => {
            setOption({
              ...option,
              playerMenuOption: 1,
            });
          }}
        >
          BEST DIFFS
        </Router>
      </RouterContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 1rem;
  border-radius: 1rem; // Rounded corners
  text-align: center; // Center-align the text
  display: flex;
  padding: 0rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  /* background-color: ${colors.baseBoxBackground}; // Light background color */

  @media (max-width: 768px) {
    padding: 1rem 0rem; // Add padding for spacing
    margin: 1rem auto; // Add space at the top and center horizontally
    width: 100%;
  }
`;

const BestTitle = styled(Title)`
  margin-top: 1rem;
  color: ${colors.baseText};
  text-align: center;
`;

const RouterContainer = styled(Components)`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: ${colors.baseBoxBackground}; */
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem auto;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0;
  }
`;

const Router = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background-color: ${colors.buttonBase};
  color: ${colors.textBlack};
  text-align: center;
  font-size: ${fontSize.media_large.small};
  border-radius: 1rem;
  text-decoration: none;

  &:hover {
    background-color: ${colors.buttonHover};
  }

  @media (max-width: 768px) {
    width: 80%;
    margin: 0.5rem 1rem;
  }
`;

export default Banner;

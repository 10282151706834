import { useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import PlayerModal from "../../../components/PlayerModal";
import { getTeamNameById, getTeamSymbolById } from "../../../utils/getTeamNameById";
import TeamModal from "../../../components/TeamModal";
import sortButton from "../../../assets/icon/sortbutton2.svg";
import FixturesModal from "../../../components/FixturesModal";
import InfoBox from "../../../components/common/InfoBox";

interface InnerFixtureDataProps {
  roundFixtures: any;
  teamInfo: any;
  currentRound: number;
  fixturesDifficultyScore: any;
}

const InnerRoundFixtureData: React.FC<InnerFixtureDataProps> = ({
  roundFixtures,
  teamInfo,
  currentRound,
  fixturesDifficultyScore,
}) => {
  const [isFixturesModalOpen, setIsFixturesModalOpen] = useState(false);
  const [fixturesData, setFixturesData] = useState(null);

  const handleOpenFixturesModal = (data: any) => {
    setIsFixturesModalOpen(true);
    setFixturesData(data);
  };

  const handleCloseFixturesModal = () => {
    setIsFixturesModalOpen(false);
  };

  const innerRoundFixtureData = (data: any) => {
    return (
      <RoundDataContainer
        onClick={() => {
          handleOpenFixturesModal(data);
        }}
      >
        {isFixturesModalOpen && (
          <FixturesModal
            isOpen={isFixturesModalOpen}
            onClose={handleCloseFixturesModal}
            allTeamInfo={teamInfo}
            fixturesInfo={fixturesData}
            currentRound={currentRound}
          />
        )}
        <RoundTeamName>{getTeamSymbolById(teamInfo, data.team_h)}</RoundTeamName>
        <RoundTeamLogo src={getTeamLogoById(teamInfo, data.team_h, "id")} alt="logo"></RoundTeamLogo>
        <RoundScore>
          {data.team_h_score} - {data.team_a_score}
        </RoundScore>
        <RoundTeamLogo src={getTeamLogoById(teamInfo, data.team_a, "id")} alt="logo"></RoundTeamLogo>
        <RoundTeamName>{getTeamSymbolById(teamInfo, data.team_a)}</RoundTeamName>
      </RoundDataContainer>
    );
  };

  return (
    <BoxContainer>
      <Box>
        <BoxTitle>Round Fixtures</BoxTitle>
        {roundFixtures.length > 0
          ? roundFixtures.map((data: any) => {
              return innerRoundFixtureData(data);
            })
          : null}
      </Box>
    </BoxContainer>
  );
};

export default InnerRoundFixtureData;

const RoundDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem;
`;
const TeamName = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: left;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const TeamLogo = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

const RoundTeamName = styled(TeamName)`
  width: 20%;
  justify-content: center;
`;

const RoundTeamLogo = styled(TeamLogo)`
  width: 2rem;
  height: 2rem;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 20%;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const RoundScore = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: center;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;
const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const BoxTitle = styled.div`
  color: ${colors.baseText};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem; // Spacing between title and content
  margin-bottom: 1rem; // Spacing between title and content
  font-size: 1.5rem; // Larger font size for title
  font-weight: 600; // Bold font weight for emphasis
`;

const Box = styled.div`
  width: calc(100% / 2 - 10px); // Responsive width for 2 boxes per row
  margin: 3px; // Uniform margin for spacing
  padding: 1.5rem; // Increased padding for better spacing
  box-sizing: border-box; // Keeps padding within the box width
  border-radius: 8px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  transition: all 0.3s ease; // Smooth transition for hover effect

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 1268px) {
    width: 100%; // Full width on smaller screens
  }
`;

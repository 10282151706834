import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getTeamNameByCode, getTeamNameById, getTeamSymbolById } from "../utils/getTeamNameById";
import { getPositionById } from "../utils/getPositionById";
import colors from "../assets/colors/colors";
import teamColors from "../assets/colors/teamColors";
import { getTeamLogoById } from "../utils/getTeamLogoById";
import { getPlayerImgSrcById } from "../utils/getPlayerImgSrcById";

import { fontSize, NumericText } from "../assets/style/font";
import { FaWindowClose } from "react-icons/fa";
import PlayerFixtureGraph from "./PlayerFixtureGraph";
import { getBackgroundColor } from "../utils/getBackgroundColor";

interface PlayerModalProps {
  isOpen: boolean;
  onClose: () => void;
  playerInfo: any; // Replace 'any' with the actual type of your playerInfo data
  allTeamInfo: any; // Replace 'any' with the actual type of your allTeamInfo data
  teamInfo: any; // Replace 'any' with the actual type of your teamInfo data
  currentRound: number;
}

interface ModalContentProps {
  teamColor: string;
}

const PlayerModal: React.FC<PlayerModalProps> = ({
  isOpen,
  onClose,
  playerInfo,
  allTeamInfo,
  teamInfo,
  currentRound,
}) => {
  const [teamColor, setTeamColor] = useState<string>("");
  const [playerImgSrc, setPlayerImgSrc] = useState<string>(
    "https://resources.premierleague.com/premierleague/photos/players/110x140/p85971.png"
  );
  const [comment, setComment] = useState<string>("");

  const getTeamColor = () => {
    try {
      setTeamColor(
        teamColors[getTeamNameByCode(allTeamInfo, teamInfo.code).toLowerCase().replace(/\s/g, "").replace("'", "")]
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getHistoryPoints = (index: number) => {
    const id = `round${index}`;
    return playerInfo.history_points[id];
  };

  useEffect(() => {
    if (!playerInfo) return;
    getTeamColor();
    setPlayerImgSrc(getPlayerImgSrcById(playerInfo.code));
  }, [playerInfo, teamInfo]);

  if (!isOpen || !playerInfo) return null;

  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent teamColor={teamColor} onClick={(e) => e.stopPropagation()}>
        <FaWindowClose
          style={{
            cursor: "pointer",
            position: "absolute",
            fontSize: "1.5rem",
            top: "10px",
            right: "10px",
          }}
          onClick={onClose}
        />

        <Header>
          <LeftHeader>
            <PlayerImg src={playerImgSrc} alt={`${playerInfo.web_name} image`} />
            <PlayerInfo>
              <Name>{playerInfo.web_name}</Name>
              <BasicInfo>Team | {teamInfo.name}</BasicInfo>
              <BasicInfo>Position | {getPositionById(playerInfo.element_type)}</BasicInfo>
            </PlayerInfo>
          </LeftHeader>
          <RightHeader>
            <Logo src={getTeamLogoById(allTeamInfo, teamInfo.id, "id")} alt="team logo" />
          </RightHeader>
        </Header>

        <Comment>{comment && <CommentContent>"{comment}"</CommentContent>}</Comment>

        <Line />

        <StatsContainer>
          <StatItem>
            <StatLabel>Overall Score</StatLabel>
            <StatValue score={playerInfo.overall_score ? playerInfo.overall_score : 0}>
              {playerInfo.overall_score ? playerInfo.overall_score : "-"}
            </StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Differential Score</StatLabel>
            <StatValue score={playerInfo.differential_score ? playerInfo.differential_score : 0}>
              {playerInfo.differential_score ? playerInfo.differential_score : "-"}
            </StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Round Points</StatLabel>
            <StatValue>{playerInfo.event_points}</StatValue>
          </StatItem>
        </StatsContainer>

        <StatsContainer>
          <StatItem>
            <StatLabel>Total Points</StatLabel>
            <StatValue>{playerInfo.total_points}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Avg Points Recent | All</StatLabel>
            <StatValue>
              {playerInfo.recent_average_points ? playerInfo.recent_average_points : playerInfo.form}
              <span> {playerInfo.points_per_game}</span>
            </StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Selected By</StatLabel>
            <StatValue>{playerInfo.selected_by_percent}%</StatValue>
          </StatItem>
        </StatsContainer>

        <Line />

        <StatsContainer>
          {playerInfo.element_type !== 1 && (
            <>
              <StatItem>
                <StatLabel>
                  Goals | <span>xG</span>
                </StatLabel>
                <StatValue>
                  {playerInfo.goals_scored}
                  <span>{playerInfo.expected_goals}</span>
                </StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>
                  Assists | <span>xA</span>
                </StatLabel>
                <StatValue>
                  {playerInfo.assists} <span>{playerInfo.expected_assists}</span>
                </StatValue>
              </StatItem>
            </>
          )}
          {playerInfo.element_type === 1 && (
            <>
              <StatItem>
                <StatLabel>Penalties Saved</StatLabel>
                <StatValue>{playerInfo.penalties_saved}</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>
                  Goals Conceded | <span>xGC</span>
                </StatLabel>
                <StatValue>
                  {playerInfo.goals_conceded}
                  <span>{playerInfo.expected_goals_conceded}</span>
                </StatValue>
              </StatItem>
            </>
          )}
          {playerInfo.element_type === 1 || playerInfo.element_type === 2 ? (
            <StatItem>
              <StatLabel>Clean Sheets</StatLabel>
              <StatValue>{playerInfo.clean_sheets}</StatValue>
            </StatItem>
          ) : (
            <StatItem>
              <StatLabel>90min xGI</StatLabel>
              <StatValue>{playerInfo.expected_goal_involvements_per_90}</StatValue>
            </StatItem>
          )}
        </StatsContainer>

        <FixturesContainer>
          <FixturesInfoTitle>Fixtures</FixturesInfoTitle>
          <PlayerFixtureGraph
            historyPoints={teamInfo.fixtures
              .map((_: any, index: number) => getHistoryPoints(index))
              .filter((points: number | null) => points !== null && points !== undefined)}
          />

          <FixturesInfoContent>
            {teamInfo.fixtures.map((fixture: any, index: number) => {
              let difficulty1 = fixture[0] ? teamInfo.fixtures_difficulty[index][0] : 0;
              let difficulty2 = fixture[1] ? teamInfo.fixtures_difficulty[index][1] : 0;
              return (
                <FixturesBox key={index}>
                  {index >= currentRound ? (
                    <Box>
                      <Title>GW{index} </Title>
                      <Content>
                        <TeamName difficulty={difficulty1}>
                          {fixture[0] ? getTeamSymbolById(allTeamInfo, fixture[0]) : "-"}
                        </TeamName>
                        <TeamName difficulty={difficulty2}>
                          {fixture[1] ? getTeamSymbolById(allTeamInfo, fixture[1]) : null}
                        </TeamName>
                        {index !== currentRound ? (
                          <Points></Points>
                        ) : (
                          <Points>{playerInfo?.event_points ? playerInfo.event_points : "-"}</Points>
                        )}
                      </Content>
                    </Box>
                  ) : null}
                </FixturesBox>
              );
            })}
          </FixturesInfoContent>

          <FixturesInfoTitle>Fixtures History</FixturesInfoTitle>
          <FixturesInfoContent>
            {teamInfo.fixtures.map((fixture: any, index: number) => {
              let difficulty1 = fixture[0] ? teamInfo.fixtures_difficulty[index][0] : 0;
              let difficulty2 = fixture[1] ? teamInfo.fixtures_difficulty[index][1] : 0;
              return (
                <FixturesBox key={index}>
                  {index < currentRound && index > 0 ? (
                    <Box>
                      <Title>GW{index} </Title>
                      <Content>
                        <TeamName difficulty={difficulty1}>
                          {fixture[0] ? getTeamSymbolById(allTeamInfo, fixture[0]) : "-"}
                        </TeamName>
                        <TeamName difficulty={difficulty2}>
                          {fixture[1] ? getTeamSymbolById(allTeamInfo, fixture[1]) : null}
                        </TeamName>
                        <Points>{playerInfo?.history_points ? getHistoryPoints(index) : "-"}</Points>
                      </Content>
                    </Box>
                  ) : null}
                </FixturesBox>
              );
            })}
          </FixturesInfoContent>
        </FixturesContainer>

        <Line />

        <AdditionalInfo>
          <AdditionalInfoTitle>Now Cost</AdditionalInfoTitle>
          <AdditionalInfoContent>£{playerInfo.now_cost / 10}m</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Event Price Change</AdditionalInfoTitle>
          <AdditionalInfoContent>£{playerInfo.cost_change_event / 10}</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Overall Price Change</AdditionalInfoTitle>
          <AdditionalInfoContent>£{playerInfo.cost_change_start / 10}</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Form</AdditionalInfoTitle>
          <AdditionalInfoContent>{playerInfo.form}</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Own Goals</AdditionalInfoTitle>
          <AdditionalInfoContent>{playerInfo.own_goals}</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Yellow / Red Cards</AdditionalInfoTitle>
          <AdditionalInfoContent>
            {playerInfo.yellow_cards} / {playerInfo.red_cards}
          </AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Transfers In</AdditionalInfoTitle>
          <AdditionalInfoContent>{playerInfo.transfers_in_event}</AdditionalInfoContent>
        </AdditionalInfo>
        <AdditionalInfo>
          <AdditionalInfoTitle>Transfers Out</AdditionalInfoTitle>
          <AdditionalInfoContent>{playerInfo.transfers_out_event}</AdditionalInfoContent>
        </AdditionalInfo>
        {playerInfo.news && (
          <AdditionalInfo>
            <AdditionalInfoTitle>News</AdditionalInfoTitle>
            <AdditionalInfoContent>{playerInfo.news}</AdditionalInfoContent>
          </AdditionalInfo>
        )}
        {playerInfo.element_type === 1 && (
          <>
            <AdditionalInfo>
              <AdditionalInfoTitle>Penalties Saved</AdditionalInfoTitle>
              <AdditionalInfoContent>{playerInfo.penalties_saved}</AdditionalInfoContent>
            </AdditionalInfo>
            <AdditionalInfo>
              <AdditionalInfoTitle>Goals Conceded</AdditionalInfoTitle>
              <AdditionalInfoContent>{playerInfo.goals_conceded}</AdditionalInfoContent>
            </AdditionalInfo>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: ${colors.textBlack};
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div<ModalContentProps>`
  background: ${({ teamColor }) => `linear-gradient(135deg, ${teamColor} 0%, rgba(255, 255, 255, 0.9) 100%)`};
  backdrop-filter: blur(10px); // Frosted glass effect
  padding: 1rem;
  border-radius: 0.8rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  position: relative;
  max-height: 90vh; /* Set the maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  transition: all 0.3s ease-out;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 12px; /* 스크롤바 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* 스크롤바의 thumb 색상 */
    border-radius: 6px; /* 스크롤바의 둥근 모서리 */
    border: 3px solid rgba(255, 255, 255, 0.8); /* thumb 주변의 여백과 색상 */
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5); /* 스크롤바 트랙의 배경색 */
    border-radius: 6px; /* 트랙의 둥근 모서리 */
  }

  @media (max-width: 500px) {
    width: 85%;
    max-height: 65vh; /* Set the maximum height */
  }
  @media (min-width: 769px) {
    width: 50%;
  }
  @media (min-width: 1268px) {
    width: 40%;
  }
  @media (min-width: 1500px) {
    width: 40%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 2rem;
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  margin: 0.5rem 0;
`;

const CommentContent = styled.div`
  color: ${colors.textBlack};
  margin-bottom: 5px;
  text-align: center;
  font-size: ${fontSize.media_large.small};

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlayerImg = styled.img`
  width: 8rem;
  border-radius: 25%;

  @media (max-width: 500px) {
    width: 4rem;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-size: ${fontSize.media_large.large};
  font-weight: bold;
  color: ${colors.textBlack};
  margin-bottom: 5px;
  text-align: left;

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.large};
  }
`;

const BasicInfo = styled(NumericText)`
  color: ${colors.textBlack};
  margin-bottom: 5px;
  text-align: left;
  font-size: ${fontSize.media_large.medium};

  @media (max-width: 500px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const Nickname = styled(BasicInfo)`
  font-weight: bold;
`;

const Logo = styled.img`
  width: 5rem;

  @media (max-width: 500px) {
    width: 2.5rem;
  }
`;

const Line = styled.hr`
  border: none;
  width: 95%;
  height: 1px;
  background-color: ${colors.line};
  margin: 1rem auto;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
`;

const StatItem = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StatLabel = styled.div`
  margin-bottom: 5px;
  text-align: center;
  font-size: ${fontSize.media_large.small};
  color: ${colors.textBlack};

  span {
    margin-left: 5px;
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const StatValue = styled(NumericText)<{ score?: number }>`
  color: ${({ score }) => (score ? (score > 80 ? colors.score1st : colors.textBlack) : colors.textBlack)};
  font-size: ${fontSize.media_large.large};
  font-weight: bold;
  text-align: center;

  span {
    font-size: ${fontSize.media_large.small};
    margin-left: 3px;
  }
`;

const FixturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${colors.textBlack};
`;

const AdditionalInfoTitle = styled.div`
  color: ${colors.textBlack};
  font-weight: bold;
`;

const AdditionalInfoContent = styled(NumericText)`
  color: ${colors.textBlack};
`;

const FixturesInfoTitle = styled.div`
  margin-top: 10px;
  color: ${colors.textBlack};
  font-weight: bold;
  font-size: ${fontSize.media_large.medium};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const FixturesInfoContent = styled(AdditionalInfoContent)`
  color: ${colors.textBlack};
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scrolling */

  /* Custom Horizontal Scrollbar */
  &::-webkit-scrollbar {
    height: 12px; /* 가로 스크롤바 높이 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5); /* thumb 색상 */
    border-radius: 6px; /* 둥근 모서리 */
    border: 3px solid rgba(255, 255, 255, 0.8); /* thumb 주변의 여백과 색상 */
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.5); /* 트랙의 배경색 */
    border-radius: 6px; /* 트랙의 둥근 모서리 */
  }
`;

const FixturesBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;

  @media (max-width: 1268px) {
  }
  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const Title = styled.div`
  color: ${colors.textBlack};
  font-weight: bold;
  text-align: left;
  font-size: ${fontSize.media_large.medium};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const Content = styled.div`
  color: #fff;
  text-align: right;
  width: 100%;
`;

const TeamName = styled.div<{ difficulty: number }>`
  color: #fff;
  background-color: ${({ difficulty }) => getBackgroundColor(difficulty)};
  text-align: center;
  font-size: ${fontSize.media_large.medium};
`;

const Points = styled.div`
  color: ${colors.textBlack};
  text-align: center;
  font-weight: bold;
`;

export default PlayerModal;

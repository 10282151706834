import React, { useEffect, useState, ButtonHTMLAttributes, Component } from "react";
import styled from "styled-components";
import colors from "../../assets/colors/colors";
import Container from "../../components/common/Container";
import { useRecoilValue } from "recoil";
import { optionState } from "../../state/option";
import TopManagers from "./components/TopManagers";
import ManagerInfo from "./components/ManagerInfo";
import { getTopManagerWorld } from "../../api/manager/getTopManagerWorld";
import { ManagerSearchHelper } from "./components/ManagerSearchHelper";

function Manager({ playerInfo, allTeamInfo }: any) {
  const option = useRecoilValue(optionState);

  useEffect(() => {
    getTopManagerWorld();
  }, [option]);

  return (
    <ManagerContainer>
      <DataContainer>
        {option.managerMenuOption === "topWorld" && <TopManagers />}
        {option.managerMenuOption === "search" ? <ManagerInfo /> : <ManagerSearchHelper />}
      </DataContainer>
    </ManagerContainer>
  );
}

export default Manager;

const ManagerContainer = styled(Container)`
  width: 95%;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

const DataContainer = styled.div`
  padding: 1rem;
  margin: auto;
  border-radius: 1rem;

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;
